<template>
  <div style="height:100%;" class='d-flex align-center text-center'>
      <div style=" width:100%" class="text-h5">Тестирование завершено! Нажмите кнопку "Продолжить" чтобы вернуться в начало.
          <br>
          <v-btn to='/revision/list' color="primary" x-large class="ma-3">Продолжить</v-btn>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>